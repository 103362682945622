import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "./permission.js";
import "element-plus/dist/index.css";
// import "@/assets/iconfont/iconfont.css";
import "default-passive-events";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "vxe-table/lib/style.css";
import VXETable from "vxe-table";

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(store).use(router).use(ElementPlus).use(VXETable).mount("#app");
